







import { ChartLabel } from "@/models/utils/c-chart.interface";
import ChartDataLabels from "chartjs-plugin-datalabels";
import {
  Chart,
  PieController,
  ChartTypeRegistry,
  ArcElement,
  Tooltip,
} from "chart.js";
import { Component, Prop, Vue } from "vue-property-decorator";

Chart.register(PieController, ArcElement, Tooltip, ChartDataLabels);

@Component
export default class CPieChart extends Vue {
  @Prop({ required: true, type: Array })
  private data: number[];

  private chart: Chart = null;

  private mounted(): void {
    const color = this.$vuetify.theme.themes.light;
    const canvas = this.$refs.canvas as HTMLCanvasElement;
    this.chart = new Chart(canvas, {
      type: "pie" as keyof ChartTypeRegistry,
      data: {
        labels: Object.values(ChartLabel),
        datasets: [
          {
            backgroundColor: [
              color.error["lighten1"],
              color.accent["lighten1"],
              color.success["lighten1"],
            ],
            data: this.data,
          },
        ],
      },
      options: {
        plugins: {
          datalabels: {
            formatter: (value, ctx) => {
              let sum = 0;
              let dataArr = ctx.chart.data.datasets[0].data;
              for (const data of dataArr) {
                sum += Number(data);
              }
              return ((value * 100) / (sum === 0 ? 1 : sum)).toFixed(0) + "%";
            },
            color: "#000",
            labels: {
              title: {
                font: {
                  weight: "bold",
                },
              },
            },
          },
        },
      },
    });
  }
}
